import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _be9dc5d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _25ecceab = () => interopDefault(import('../pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _5f2d4610 = () => interopDefault(import('../pages/halloffame/index.vue' /* webpackChunkName: "pages/halloffame/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _32a33f4a = () => interopDefault(import('../pages/myaccount/index.vue' /* webpackChunkName: "pages/myaccount/index" */))
const _61429a50 = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _cc080460 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _2bfb58d8 = () => interopDefault(import('../pages/request/index.vue' /* webpackChunkName: "pages/request/index" */))
const _33ca1164 = () => interopDefault(import('../pages/admin/users.vue' /* webpackChunkName: "pages/admin/users" */))
const _df94b5aa = () => interopDefault(import('../pages/forgotpassword/reset/index.vue' /* webpackChunkName: "pages/forgotpassword/reset/index" */))
const _0aa2a9af = () => interopDefault(import('../pages/verify/_id/_hash/index.vue' /* webpackChunkName: "pages/verify/_id/_hash/index" */))
const _a27a4f8e = () => interopDefault(import('../pages/_race/index.vue' /* webpackChunkName: "pages/_race/index" */))
const _293e89b2 = () => interopDefault(import('../pages/_race/myprediction/index.vue' /* webpackChunkName: "pages/_race/myprediction/index" */))
const _08f9fea8 = () => interopDefault(import('../pages/_race/organizepool.vue' /* webpackChunkName: "pages/_race/organizepool" */))
const _163390c1 = () => interopDefault(import('../pages/_race/pools/index.vue' /* webpackChunkName: "pages/_race/pools/index" */))
const _6a344cca = () => interopDefault(import('../pages/_race/prizes.vue' /* webpackChunkName: "pages/_race/prizes" */))
const _633e5dde = () => interopDefault(import('../pages/_race/rules.vue' /* webpackChunkName: "pages/_race/rules" */))
const _4379b15e = () => interopDefault(import('../pages/_race/stages.vue' /* webpackChunkName: "pages/_race/stages" */))
const _8104e0ee = () => interopDefault(import('../pages/_race/stats/index.vue' /* webpackChunkName: "pages/_race/stats/index" */))
const _5512b1c6 = () => interopDefault(import('../pages/_race/teams.vue' /* webpackChunkName: "pages/_race/teams" */))
const _209f6935 = () => interopDefault(import('../pages/_race/userteams/index.vue' /* webpackChunkName: "pages/_race/userteams/index" */))
const _6fe08df8 = () => interopDefault(import('../pages/_race/rankinguserteams/season.vue' /* webpackChunkName: "pages/_race/rankinguserteams/season" */))
const _7bcdf65b = () => interopDefault(import('../pages/_race/rankinguserteams/weekwinner/_week/index.vue' /* webpackChunkName: "pages/_race/rankinguserteams/weekwinner/_week/index" */))
const _2caea503 = () => interopDefault(import('../pages/_race/pool/_slug/index.vue' /* webpackChunkName: "pages/_race/pool/_slug/index" */))
const _1bd05370 = () => interopDefault(import('../pages/_race/raceresults/_number/index.vue' /* webpackChunkName: "pages/_race/raceresults/_number/index" */))
const _01f06434 = () => interopDefault(import('../pages/_race/rankingpools/_number/index.vue' /* webpackChunkName: "pages/_race/rankingpools/_number/index" */))
const _07fa8c5a = () => interopDefault(import('../pages/_race/rankinguserteams/_number/index.vue' /* webpackChunkName: "pages/_race/rankinguserteams/_number/index" */))
const _49dbf50f = () => interopDefault(import('../pages/_race/userteam/_slug/index.vue' /* webpackChunkName: "pages/_race/userteam/_slug/index" */))
const _737baa64 = () => interopDefault(import('../pages/_race/rankinguserteams/_number/rankingpoints/index.vue' /* webpackChunkName: "pages/_race/rankinguserteams/_number/rankingpoints/index" */))
const _dec1b75c = () => interopDefault(import('../pages/_race/pool/_slug/ranking/weekwinner/_week/index.vue' /* webpackChunkName: "pages/_race/pool/_slug/ranking/weekwinner/_week/index" */))
const _57da7c83 = () => interopDefault(import('../pages/_race/pool/_slug/ranking/_number/index.vue' /* webpackChunkName: "pages/_race/pool/_slug/ranking/_number/index" */))
const _28db9ecd = () => interopDefault(import('../pages/_race/pool/_slug/ranking/_number/rankingpoints/index.vue' /* webpackChunkName: "pages/_race/pool/_slug/ranking/_number/rankingpoints/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _2dfb1658,
    name: "index___de"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/es",
    component: _2dfb1658,
    name: "index___es"
  }, {
    path: "/fr",
    component: _2dfb1658,
    name: "index___fr"
  }, {
    path: "/it",
    component: _2dfb1658,
    name: "index___it"
  }, {
    path: "/nl",
    component: _2dfb1658,
    name: "index___nl"
  }, {
    path: "/de/admin",
    component: _be9dc5d0,
    name: "admin___de"
  }, {
    path: "/de/forgotpassword",
    component: _25ecceab,
    name: "forgotpassword___de"
  }, {
    path: "/de/halloffame",
    component: _5f2d4610,
    name: "halloffame___de"
  }, {
    path: "/de/login",
    component: _cad79e9c,
    name: "login___de"
  }, {
    path: "/de/myaccount",
    component: _32a33f4a,
    name: "myaccount___de"
  }, {
    path: "/de/promo",
    component: _61429a50,
    name: "promo___de"
  }, {
    path: "/de/register",
    component: _cc080460,
    name: "register___de"
  }, {
    path: "/de/request",
    component: _2bfb58d8,
    name: "request___de"
  }, {
    path: "/en/admin",
    component: _be9dc5d0,
    name: "admin___en"
  }, {
    path: "/en/forgotpassword",
    component: _25ecceab,
    name: "forgotpassword___en"
  }, {
    path: "/en/halloffame",
    component: _5f2d4610,
    name: "halloffame___en"
  }, {
    path: "/en/login",
    component: _cad79e9c,
    name: "login___en"
  }, {
    path: "/en/myaccount",
    component: _32a33f4a,
    name: "myaccount___en"
  }, {
    path: "/en/promo",
    component: _61429a50,
    name: "promo___en"
  }, {
    path: "/en/register",
    component: _cc080460,
    name: "register___en"
  }, {
    path: "/en/request",
    component: _2bfb58d8,
    name: "request___en"
  }, {
    path: "/es/admin",
    component: _be9dc5d0,
    name: "admin___es"
  }, {
    path: "/es/forgotpassword",
    component: _25ecceab,
    name: "forgotpassword___es"
  }, {
    path: "/es/halloffame",
    component: _5f2d4610,
    name: "halloffame___es"
  }, {
    path: "/es/login",
    component: _cad79e9c,
    name: "login___es"
  }, {
    path: "/es/myaccount",
    component: _32a33f4a,
    name: "myaccount___es"
  }, {
    path: "/es/promo",
    component: _61429a50,
    name: "promo___es"
  }, {
    path: "/es/register",
    component: _cc080460,
    name: "register___es"
  }, {
    path: "/es/request",
    component: _2bfb58d8,
    name: "request___es"
  }, {
    path: "/fr/admin",
    component: _be9dc5d0,
    name: "admin___fr"
  }, {
    path: "/fr/forgotpassword",
    component: _25ecceab,
    name: "forgotpassword___fr"
  }, {
    path: "/fr/halloffame",
    component: _5f2d4610,
    name: "halloffame___fr"
  }, {
    path: "/fr/login",
    component: _cad79e9c,
    name: "login___fr"
  }, {
    path: "/fr/myaccount",
    component: _32a33f4a,
    name: "myaccount___fr"
  }, {
    path: "/fr/promo",
    component: _61429a50,
    name: "promo___fr"
  }, {
    path: "/fr/register",
    component: _cc080460,
    name: "register___fr"
  }, {
    path: "/fr/request",
    component: _2bfb58d8,
    name: "request___fr"
  }, {
    path: "/it/admin",
    component: _be9dc5d0,
    name: "admin___it"
  }, {
    path: "/it/forgotpassword",
    component: _25ecceab,
    name: "forgotpassword___it"
  }, {
    path: "/it/halloffame",
    component: _5f2d4610,
    name: "halloffame___it"
  }, {
    path: "/it/login",
    component: _cad79e9c,
    name: "login___it"
  }, {
    path: "/it/myaccount",
    component: _32a33f4a,
    name: "myaccount___it"
  }, {
    path: "/it/promo",
    component: _61429a50,
    name: "promo___it"
  }, {
    path: "/it/register",
    component: _cc080460,
    name: "register___it"
  }, {
    path: "/it/request",
    component: _2bfb58d8,
    name: "request___it"
  }, {
    path: "/nl/admin",
    component: _be9dc5d0,
    name: "admin___nl"
  }, {
    path: "/nl/forgotpassword",
    component: _25ecceab,
    name: "forgotpassword___nl"
  }, {
    path: "/nl/halloffame",
    component: _5f2d4610,
    name: "halloffame___nl"
  }, {
    path: "/nl/login",
    component: _cad79e9c,
    name: "login___nl"
  }, {
    path: "/nl/myaccount",
    component: _32a33f4a,
    name: "myaccount___nl"
  }, {
    path: "/nl/promo",
    component: _61429a50,
    name: "promo___nl"
  }, {
    path: "/nl/register",
    component: _cc080460,
    name: "register___nl"
  }, {
    path: "/nl/request",
    component: _2bfb58d8,
    name: "request___nl"
  }, {
    path: "/de/admin/users",
    component: _33ca1164,
    name: "admin-users___de"
  }, {
    path: "/de/forgotpassword/reset",
    component: _df94b5aa,
    name: "forgotpassword-reset___de"
  }, {
    path: "/en/admin/users",
    component: _33ca1164,
    name: "admin-users___en"
  }, {
    path: "/en/forgotpassword/reset",
    component: _df94b5aa,
    name: "forgotpassword-reset___en"
  }, {
    path: "/es/admin/users",
    component: _33ca1164,
    name: "admin-users___es"
  }, {
    path: "/es/forgotpassword/reset",
    component: _df94b5aa,
    name: "forgotpassword-reset___es"
  }, {
    path: "/fr/admin/users",
    component: _33ca1164,
    name: "admin-users___fr"
  }, {
    path: "/fr/forgotpassword/reset",
    component: _df94b5aa,
    name: "forgotpassword-reset___fr"
  }, {
    path: "/it/admin/users",
    component: _33ca1164,
    name: "admin-users___it"
  }, {
    path: "/it/forgotpassword/reset",
    component: _df94b5aa,
    name: "forgotpassword-reset___it"
  }, {
    path: "/nl/admin/users",
    component: _33ca1164,
    name: "admin-users___nl"
  }, {
    path: "/nl/forgotpassword/reset",
    component: _df94b5aa,
    name: "forgotpassword-reset___nl"
  }, {
    path: "/de/verify/:id?/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___de"
  }, {
    path: "/en/verify/:id?/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___en"
  }, {
    path: "/es/verify/:id?/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___es"
  }, {
    path: "/fr/verify/:id?/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___fr"
  }, {
    path: "/it/verify/:id?/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___it"
  }, {
    path: "/nl/verify/:id?/:hash",
    component: _0aa2a9af,
    name: "verify-id-hash___nl"
  }, {
    path: "/de/:race",
    component: _a27a4f8e,
    name: "race___de"
  }, {
    path: "/en/:race",
    component: _a27a4f8e,
    name: "race___en"
  }, {
    path: "/es/:race",
    component: _a27a4f8e,
    name: "race___es"
  }, {
    path: "/fr/:race",
    component: _a27a4f8e,
    name: "race___fr"
  }, {
    path: "/it/:race",
    component: _a27a4f8e,
    name: "race___it"
  }, {
    path: "/nl/:race",
    component: _a27a4f8e,
    name: "race___nl"
  }, {
    path: "/de/:race/myprediction",
    component: _293e89b2,
    name: "race-myprediction___de"
  }, {
    path: "/de/:race/organizepool",
    component: _08f9fea8,
    name: "race-organizepool___de"
  }, {
    path: "/de/:race/pools",
    component: _163390c1,
    name: "race-pools___de"
  }, {
    path: "/de/:race/prizes",
    component: _6a344cca,
    name: "race-prizes___de"
  }, {
    path: "/de/:race/rules",
    component: _633e5dde,
    name: "race-rules___de"
  }, {
    path: "/de/:race/stages",
    component: _4379b15e,
    name: "race-stages___de"
  }, {
    path: "/de/:race/stats",
    component: _8104e0ee,
    name: "race-stats___de"
  }, {
    path: "/de/:race/teams",
    component: _5512b1c6,
    name: "race-teams___de"
  }, {
    path: "/de/:race/userteams",
    component: _209f6935,
    name: "race-userteams___de"
  }, {
    path: "/en/:race/myprediction",
    component: _293e89b2,
    name: "race-myprediction___en"
  }, {
    path: "/en/:race/organizepool",
    component: _08f9fea8,
    name: "race-organizepool___en"
  }, {
    path: "/en/:race/pools",
    component: _163390c1,
    name: "race-pools___en"
  }, {
    path: "/en/:race/prizes",
    component: _6a344cca,
    name: "race-prizes___en"
  }, {
    path: "/en/:race/rules",
    component: _633e5dde,
    name: "race-rules___en"
  }, {
    path: "/en/:race/stages",
    component: _4379b15e,
    name: "race-stages___en"
  }, {
    path: "/en/:race/stats",
    component: _8104e0ee,
    name: "race-stats___en"
  }, {
    path: "/en/:race/teams",
    component: _5512b1c6,
    name: "race-teams___en"
  }, {
    path: "/en/:race/userteams",
    component: _209f6935,
    name: "race-userteams___en"
  }, {
    path: "/es/:race/myprediction",
    component: _293e89b2,
    name: "race-myprediction___es"
  }, {
    path: "/es/:race/organizepool",
    component: _08f9fea8,
    name: "race-organizepool___es"
  }, {
    path: "/es/:race/pools",
    component: _163390c1,
    name: "race-pools___es"
  }, {
    path: "/es/:race/prizes",
    component: _6a344cca,
    name: "race-prizes___es"
  }, {
    path: "/es/:race/rules",
    component: _633e5dde,
    name: "race-rules___es"
  }, {
    path: "/es/:race/stages",
    component: _4379b15e,
    name: "race-stages___es"
  }, {
    path: "/es/:race/stats",
    component: _8104e0ee,
    name: "race-stats___es"
  }, {
    path: "/es/:race/teams",
    component: _5512b1c6,
    name: "race-teams___es"
  }, {
    path: "/es/:race/userteams",
    component: _209f6935,
    name: "race-userteams___es"
  }, {
    path: "/fr/:race/myprediction",
    component: _293e89b2,
    name: "race-myprediction___fr"
  }, {
    path: "/fr/:race/organizepool",
    component: _08f9fea8,
    name: "race-organizepool___fr"
  }, {
    path: "/fr/:race/pools",
    component: _163390c1,
    name: "race-pools___fr"
  }, {
    path: "/fr/:race/prizes",
    component: _6a344cca,
    name: "race-prizes___fr"
  }, {
    path: "/fr/:race/rules",
    component: _633e5dde,
    name: "race-rules___fr"
  }, {
    path: "/fr/:race/stages",
    component: _4379b15e,
    name: "race-stages___fr"
  }, {
    path: "/fr/:race/stats",
    component: _8104e0ee,
    name: "race-stats___fr"
  }, {
    path: "/fr/:race/teams",
    component: _5512b1c6,
    name: "race-teams___fr"
  }, {
    path: "/fr/:race/userteams",
    component: _209f6935,
    name: "race-userteams___fr"
  }, {
    path: "/it/:race/myprediction",
    component: _293e89b2,
    name: "race-myprediction___it"
  }, {
    path: "/it/:race/organizepool",
    component: _08f9fea8,
    name: "race-organizepool___it"
  }, {
    path: "/it/:race/pools",
    component: _163390c1,
    name: "race-pools___it"
  }, {
    path: "/it/:race/prizes",
    component: _6a344cca,
    name: "race-prizes___it"
  }, {
    path: "/it/:race/rules",
    component: _633e5dde,
    name: "race-rules___it"
  }, {
    path: "/it/:race/stages",
    component: _4379b15e,
    name: "race-stages___it"
  }, {
    path: "/it/:race/stats",
    component: _8104e0ee,
    name: "race-stats___it"
  }, {
    path: "/it/:race/teams",
    component: _5512b1c6,
    name: "race-teams___it"
  }, {
    path: "/it/:race/userteams",
    component: _209f6935,
    name: "race-userteams___it"
  }, {
    path: "/nl/:race/myprediction",
    component: _293e89b2,
    name: "race-myprediction___nl"
  }, {
    path: "/nl/:race/organizepool",
    component: _08f9fea8,
    name: "race-organizepool___nl"
  }, {
    path: "/nl/:race/pools",
    component: _163390c1,
    name: "race-pools___nl"
  }, {
    path: "/nl/:race/prizes",
    component: _6a344cca,
    name: "race-prizes___nl"
  }, {
    path: "/nl/:race/rules",
    component: _633e5dde,
    name: "race-rules___nl"
  }, {
    path: "/nl/:race/stages",
    component: _4379b15e,
    name: "race-stages___nl"
  }, {
    path: "/nl/:race/stats",
    component: _8104e0ee,
    name: "race-stats___nl"
  }, {
    path: "/nl/:race/teams",
    component: _5512b1c6,
    name: "race-teams___nl"
  }, {
    path: "/nl/:race/userteams",
    component: _209f6935,
    name: "race-userteams___nl"
  }, {
    path: "/de/:race/rankinguserteams/season",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___de"
  }, {
    path: "/en/:race/rankinguserteams/season",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___en"
  }, {
    path: "/es/:race/rankinguserteams/season",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___es"
  }, {
    path: "/fr/:race/rankinguserteams/season",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___fr"
  }, {
    path: "/it/:race/rankinguserteams/season",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___it"
  }, {
    path: "/nl/:race/rankinguserteams/season",
    component: _6fe08df8,
    name: "race-rankinguserteams-season___nl"
  }, {
    path: "/de/:race/rankinguserteams/weekwinner/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___de"
  }, {
    path: "/en/:race/rankinguserteams/weekwinner/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___en"
  }, {
    path: "/es/:race/rankinguserteams/weekwinner/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___es"
  }, {
    path: "/fr/:race/rankinguserteams/weekwinner/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___fr"
  }, {
    path: "/it/:race/rankinguserteams/weekwinner/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___it"
  }, {
    path: "/nl/:race/rankinguserteams/weekwinner/:week",
    component: _7bcdf65b,
    name: "race-rankinguserteams-weekwinner-week___nl"
  }, {
    path: "/de/:race/pool/:slug",
    component: _2caea503,
    name: "race-pool-slug___de"
  }, {
    path: "/de/:race/raceresults/:number",
    component: _1bd05370,
    name: "race-raceresults-number___de"
  }, {
    path: "/de/:race/rankingpools/:number",
    component: _01f06434,
    name: "race-rankingpools-number___de"
  }, {
    path: "/de/:race/rankinguserteams/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___de"
  }, {
    path: "/de/:race/userteam/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___de"
  }, {
    path: "/en/:race/pool/:slug",
    component: _2caea503,
    name: "race-pool-slug___en"
  }, {
    path: "/en/:race/raceresults/:number",
    component: _1bd05370,
    name: "race-raceresults-number___en"
  }, {
    path: "/en/:race/rankingpools/:number",
    component: _01f06434,
    name: "race-rankingpools-number___en"
  }, {
    path: "/en/:race/rankinguserteams/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___en"
  }, {
    path: "/en/:race/userteam/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___en"
  }, {
    path: "/es/:race/pool/:slug",
    component: _2caea503,
    name: "race-pool-slug___es"
  }, {
    path: "/es/:race/raceresults/:number",
    component: _1bd05370,
    name: "race-raceresults-number___es"
  }, {
    path: "/es/:race/rankingpools/:number",
    component: _01f06434,
    name: "race-rankingpools-number___es"
  }, {
    path: "/es/:race/rankinguserteams/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___es"
  }, {
    path: "/es/:race/userteam/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___es"
  }, {
    path: "/fr/:race/pool/:slug",
    component: _2caea503,
    name: "race-pool-slug___fr"
  }, {
    path: "/fr/:race/raceresults/:number",
    component: _1bd05370,
    name: "race-raceresults-number___fr"
  }, {
    path: "/fr/:race/rankingpools/:number",
    component: _01f06434,
    name: "race-rankingpools-number___fr"
  }, {
    path: "/fr/:race/rankinguserteams/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___fr"
  }, {
    path: "/fr/:race/userteam/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___fr"
  }, {
    path: "/it/:race/pool/:slug",
    component: _2caea503,
    name: "race-pool-slug___it"
  }, {
    path: "/it/:race/raceresults/:number",
    component: _1bd05370,
    name: "race-raceresults-number___it"
  }, {
    path: "/it/:race/rankingpools/:number",
    component: _01f06434,
    name: "race-rankingpools-number___it"
  }, {
    path: "/it/:race/rankinguserteams/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___it"
  }, {
    path: "/it/:race/userteam/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___it"
  }, {
    path: "/nl/:race/pool/:slug",
    component: _2caea503,
    name: "race-pool-slug___nl"
  }, {
    path: "/nl/:race/raceresults/:number",
    component: _1bd05370,
    name: "race-raceresults-number___nl"
  }, {
    path: "/nl/:race/rankingpools/:number",
    component: _01f06434,
    name: "race-rankingpools-number___nl"
  }, {
    path: "/nl/:race/rankinguserteams/:number",
    component: _07fa8c5a,
    name: "race-rankinguserteams-number___nl"
  }, {
    path: "/nl/:race/userteam/:slug",
    component: _49dbf50f,
    name: "race-userteam-slug___nl"
  }, {
    path: "/de/:race/rankinguserteams/:number?/rankingpoints",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___de"
  }, {
    path: "/en/:race/rankinguserteams/:number?/rankingpoints",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___en"
  }, {
    path: "/es/:race/rankinguserteams/:number?/rankingpoints",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___es"
  }, {
    path: "/fr/:race/rankinguserteams/:number?/rankingpoints",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___fr"
  }, {
    path: "/it/:race/rankinguserteams/:number?/rankingpoints",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___it"
  }, {
    path: "/nl/:race/rankinguserteams/:number?/rankingpoints",
    component: _737baa64,
    name: "race-rankinguserteams-number-rankingpoints___nl"
  }, {
    path: "/de/:race/pool/:slug?/ranking/weekwinner/:week",
    component: _dec1b75c,
    name: "race-pool-slug-ranking-weekwinner-week___de"
  }, {
    path: "/en/:race/pool/:slug?/ranking/weekwinner/:week",
    component: _dec1b75c,
    name: "race-pool-slug-ranking-weekwinner-week___en"
  }, {
    path: "/es/:race/pool/:slug?/ranking/weekwinner/:week",
    component: _dec1b75c,
    name: "race-pool-slug-ranking-weekwinner-week___es"
  }, {
    path: "/fr/:race/pool/:slug?/ranking/weekwinner/:week",
    component: _dec1b75c,
    name: "race-pool-slug-ranking-weekwinner-week___fr"
  }, {
    path: "/it/:race/pool/:slug?/ranking/weekwinner/:week",
    component: _dec1b75c,
    name: "race-pool-slug-ranking-weekwinner-week___it"
  }, {
    path: "/nl/:race/pool/:slug?/ranking/weekwinner/:week",
    component: _dec1b75c,
    name: "race-pool-slug-ranking-weekwinner-week___nl"
  }, {
    path: "/de/:race/pool/:slug?/ranking/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___de"
  }, {
    path: "/en/:race/pool/:slug?/ranking/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___en"
  }, {
    path: "/es/:race/pool/:slug?/ranking/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___es"
  }, {
    path: "/fr/:race/pool/:slug?/ranking/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___fr"
  }, {
    path: "/it/:race/pool/:slug?/ranking/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___it"
  }, {
    path: "/nl/:race/pool/:slug?/ranking/:number",
    component: _57da7c83,
    name: "race-pool-slug-ranking-number___nl"
  }, {
    path: "/de/:race/pool/:slug?/ranking/:number?/rankingpoints",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___de"
  }, {
    path: "/en/:race/pool/:slug?/ranking/:number?/rankingpoints",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___en"
  }, {
    path: "/es/:race/pool/:slug?/ranking/:number?/rankingpoints",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___es"
  }, {
    path: "/fr/:race/pool/:slug?/ranking/:number?/rankingpoints",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___fr"
  }, {
    path: "/it/:race/pool/:slug?/ranking/:number?/rankingpoints",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___it"
  }, {
    path: "/nl/:race/pool/:slug?/ranking/:number?/rankingpoints",
    component: _28db9ecd,
    name: "race-pool-slug-ranking-number-rankingpoints___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
